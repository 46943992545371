@font-face {
  font-family: 'Digital Numbers Regular';
  src: url('../assets/fonts/DigitalNumbersRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

:root {
  --min-screen-width: 320px;
  --base-background-color: #f0f2fa;
}

* {
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden;
}

body {
  min-width: var(--min-screen-width, 320px);
  min-height: var(--full-height, 100vh);
  color: #2c2f54;
  background-color: var(--base-background-color);
  overflow-x: hidden;
}

#root {
  position: relative;
  height: var(--full-height, 100vh);
}

iframe {
  border: none;
}

::-webkit-scrollbar {
  width: 0;
}

/* Modern CSS Reset - https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements */
ul,
ol {
  list-style: none;
}

a {
  text-decoration-skip-ink: auto;
  text-decoration: none;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

.fade-enter {
  opacity: 0;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in;
}

.fade-exit {
  opacity: 1;
  z-index: -1;
  position: relative;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  z-index: -1;
  position: relative;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
